import { useState, useEffect, useRef } from 'react';
import { scoreRanking, getQuizzesTotalPoints, getDetaislUser, getBadges } from 'modules/services/scores';

export const useFetchRankings = (payload) => {
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])


    useEffect(() => {

        setState({ data: null, loading: true, error: null });

        scoreRanking(payload).then(data => {

            if (isMounted.current) {
                setState({
                    loading: false,
                    error: null,
                    data: data?.data
                });
            }

        })
            .catch(() => {
                setState({
                    data: null,
                    loading: false,
                    error: 'No se pudo cargar la info'
                })
            })

    }, [])

    return state;
}

export const useFetchTotalPoints = (payload) => {
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])


    useEffect(() => {

        setState({ data: null, loading: true, error: null });

        getQuizzesTotalPoints(payload).then(data => {

            if (isMounted.current) {
                setState({
                    loading: false,
                    error: null,
                    data: data?.data
                });
            }

        })
            .catch(() => {
                setState({
                    data: null,
                    loading: false,
                    error: 'No se pudo cargar la info'
                })
            })

    }, [payload])

    return state;
}

export const useFetchGetDetailUser = (payload) => {
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])


    useEffect(() => {

        setState({ data: null, loading: true, error: null });

        getDetaislUser(payload).then(data => {

            if (isMounted.current) {
                setState({
                    loading: false,
                    error: null,
                    data: data?.data
                });
            }

        })
            .catch(() => {
                setState({
                    data: null,
                    loading: false,
                    error: 'No se pudo cargar la info'
                })
            })

    }, [payload])

    return state;
}


export const useFetchGetBadges = (employeeid, program_id) => {
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])


    useEffect(() => {

        setState({ data: null, loading: true, error: null });

        getBadges(employeeid, program_id).then(data => {

            if (isMounted.current) {
                setState({
                    loading: false,
                    error: null,
                    data: data?.data
                });
            }

        })
            .catch(() => {
                setState({
                    data: null,
                    loading: false,
                    error: 'No se pudo cargar la info'
                })
            })

    }, [])

    return state;
}