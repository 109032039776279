import { SCORES_TYPES } from "./scoresActionTypes";

const initialState = {
  scoreHome: [],
  loadingScoreHome: true,
  errorScoreHome: null,
  scoreModule: [],
  loadingScoreModule: true,
  errorScoreModule: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SCORES_TYPES.SCORES_BY_PROGRAM_START:
      return {
        ...state,
        loadingScoreHome: true,
      };
    case SCORES_TYPES.SCORES_BY_PROGRAM_SUCCESS:
      return {
        ...state,
        scoreHome: payload,
        errorScoreHome: null,
        loadingScoreHome: false,
      };
    case SCORES_TYPES.SCORES_BY_PROGRAM_ERROR:
      return {
        ...state,
        errorScoreHome: payload,
        loadingScoreHome: false,
      };
    case SCORES_TYPES.SCORES_BY_MODULE_START:
      return {
        ...state,
        loadingScoreModule: true,
      };
    case SCORES_TYPES.SCORES_BY_MODULE_SUCCESS:
      return {
        ...state,
        scoreModule: payload,
        errorScoreModule: null,
        loadingScoreModule: false,
      };
    case SCORES_TYPES.SCORES_BY_MODULE_ERROR:
      return {
        ...state,
        errorScoreModule: payload,
        loadingScoreModule: false,
      };
    default:
      return state;
  }
};
