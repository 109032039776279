import { POSTUSEROBJECTSTATUS_TYPES } from "./userObjectStatusActionTypes";

const initialState = {
  postUserObjectStatus: [],
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POSTUSEROBJECTSTATUS_TYPES.POST_USER_OBJECT_STATUS_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case POSTUSEROBJECTSTATUS_TYPES.POST_USER_OBJECT_STATUS_SUCCESS:
      return {
        ...state,
        postUserObjectStatus: payload.data,
        loading: false,
      };
    case POSTUSEROBJECTSTATUS_TYPES.POST_USER_OBJECT_STATUS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
