import axios from "axios";
import AuthToken from "../authToken.js";

const API_URL = process.env.REACT_APP_API_URL;

const authAxios = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

authAxios.interceptors.request.use(
  async function (options) {
    const token = AuthToken.get();

    if (token) {
      options.headers["x-bbvaton"] = token;
    }
    return options;
  },
  function (error) {
    console.log("Request error", error);
    return Promise.reject(error);
  }
);

export default authAxios;
