import { TIMER_HISTORY_TYPES } from "./timerHistoryTypes";

const initialState = {
  timerHistory: null,
  loadingGet: true,
  errorGet: null,
  loadingPost: true,
  errorPost: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TIMER_HISTORY_TYPES.GET_TIMER_HISTORY_START:
      return {
        ...state,
        loadingGet: true,
      };
    case TIMER_HISTORY_TYPES.GET_TIMER_HISTORY_SUCCESS:
      return {
        ...state,
        timerHistory: payload.data,
        errorGet: null,
        loadingGet: false,
      };
    case TIMER_HISTORY_TYPES.GET_TIMER_HISTORY_ERROR:
      return {
        ...state,
        errorGet: payload,
        loadingGet: false,
      };
    case TIMER_HISTORY_TYPES.POST_TIMER_HISTORY_START:
      return {
        ...state,
        loadingPost: true,
      };
    case TIMER_HISTORY_TYPES.POST_TIMER_HISTORY_SUCCESS:
      return {
        ...state,
        errorPost: null,
        loadingPost: false,
      };
    case TIMER_HISTORY_TYPES.POST_TIMER_HISTORY_ERROR:
      return {
        ...state,
        errorPost: payload,
        loadingPost: false,
      };
    default:
      return state;
  }
};
