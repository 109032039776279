import { MODULES_TYPES } from "./modulesActionTypes";
import { filterModulesByStatus } from "shared/utils/filterDataByStatus";

const initialState = {
  modules: [],
  programID: "",
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MODULES_TYPES.MODULES_BY_PROGRMAS_START:
      return {
        ...state,
        programID: payload,
        loading: true,
      };
    case MODULES_TYPES.MODULES_BY_PROGRMAS_SUCCESS:
      return {
        ...state,
        modules: filterModulesByStatus(payload.data.Modules, payload),
        programID: payload.data.program_id,
        loading: false,
      };
    case MODULES_TYPES.MODULES_BY_PROGRMAS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
