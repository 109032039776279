import React from "react";
import ReactDOM from "react-dom";
import { pdfjs } from "react-pdf";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "shared/fonts/fonts.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
