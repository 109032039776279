import React from "react";
import AppRouter from "./routes/AppRouter";
import { Provider } from "react-redux";
import { store } from "modules/store";

const App = () => {
  console.log('V1.5 Versión Septiembre');

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};
export default App;
