import { AUTH_SAML_TYPES } from "./authSamlActionTypes";

const initialState = {
  isEmployeeIDVerified: false,
  isAuthenticated: false,
  dataSaml: null,
  loading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_SAML_TYPES.AUTH_LOGIN_WITH_SAML_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case AUTH_SAML_TYPES.AUTH_LOGIN_WITH_SAML_SUCCESS: {
      console.log(payload);
      return {
        ...state,
        isEmployeeIDVerified: true,
        isAuthenticated: true,
        dataSaml: payload,
        loading: false,
      };
    }
    case AUTH_SAML_TYPES.AUTH_LOGIN_WITH_SAML_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};