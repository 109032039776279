import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomLoadable from "shared/CustomLoadable";
import routes from "views/routes";

import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

ReactGA.initialize('UA-203851928-1', {
  debug: true,
  titleCase: false,
});

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  console.log('GA Working');
});

export default function AppRouter() {
  /* const currentUser = useSelector((state) => state.auth); */
  const currentUser = useSelector((state) => state.authSaml);
  return (
    <Router>
      <Switch>
        {routes.publicRoutes.map((route) => (
          <PublicRouter
            currentUser={currentUser}
            key={route.path}
            exact
            path={route.path}
            component={CustomLoadable({
              loader: route.loader,
            })}
          />
        ))}

        {routes.privateRoutes.map((route) => (
          <PrivateRouter
            key={route.path}
            currentUser={currentUser}
            component={CustomLoadable({
              loader: route.loader,
            })}
            path={route.path}
            exact={Boolean(route.exact)}
          />
        ))}

        <Redirect to="/login" />
      </Switch>
    </Router>
  );
}
