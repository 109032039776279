import { STATE_HISTORY_TYPES } from "./stateHistoryTypes";

const initialState = {
  stateHistory: null,
  loadingGet: true,
  errorGet: null,
  loadingPost: true,
  errorPost: null,
  avatar: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STATE_HISTORY_TYPES.GET_STATE_HISTORY_START:
      return {
        ...state,
        loadingGet: true,
      };
    case STATE_HISTORY_TYPES.GET_STATE_HISTORY_SUCCESS:
      return {
        ...state,
        stateHistory: payload.data,
        errorGet: null,
        loadingGet: false,
      };
    case STATE_HISTORY_TYPES.GET_STATE_HISTORY_ERROR:
      return {
        ...state,
        errorGet: payload,
        loadingGet: false,
      };
    case STATE_HISTORY_TYPES.POST_STATE_HISTORY_START:
      return {
        ...state,
        loadingPost: true,
      };
    case STATE_HISTORY_TYPES.POST_STATE_HISTORY_SUCCESS:
      return {
        ...state,
        errorPost: null,
        loadingPost: false,
      };
    case STATE_HISTORY_TYPES.POST_STATE_HISTORY_ERROR:
      return {
        ...state,
        errorPost: payload,
        loadingPost: false,
      };
    case STATE_HISTORY_TYPES.GET_STATE_HISTORY_AVATAR:
      return {
        ...state,
        avatar: payload.data.avatar,
        errorGet: null,
        loadingGet: false,
      };
    case STATE_HISTORY_TYPES.POST_STATE_HISTORY_AVATAR:
      return Object.assign({}, state, {
        avatar: payload
      });
    default:
      return state;
  }
};
