import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import AuthChecker from "shared/utils/authChecker";
import AuthToken from "modules/shared/authToken";

const PublicRouter = ({ currentUser, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        /*   const authChecker = new AuthChecker(currentUser); */

        if (AuthToken.get()/*  && authChecker.isAuthenticated */) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRouter;

PublicRouter.propTypes = {
  currentUser: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};
