import { AUTH_TYPES } from "./authActionTypes";

const initialState = {
  isEmployeeIDVerified: false,
  isAuthenticated: false,
  employeeID: "",
  loading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_START:
      return {
        ...state,
        employeeID: payload,
        loading: true,
      };
    case AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: payload,
        isEmployeeIDVerified: true,
        loading: false,
      };
    case AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_SUCCESS: {
      return {
        ...state,
        isEmployeeIDVerified: false,
        isAuthenticated: true,
        data: payload,
        loading: false,
      };
    }
    case AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_SUCCESS: {
      return {
        ...state,
        isEmployeeIDVerified: false,
        isAuthenticated: true,
        data: payload,
        employeeID: payload.data.employeeid,
        loading: false,
      };
    }
    case AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case AUTH_TYPES.AUTH_LOGOUT_RESET_STORE: {
      return {
        storage: null
      }
    }
    default:
      return state;
  }
};
