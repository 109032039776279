import { combineReducers } from "redux";

import auth from "./auth/authReducers.js";
import authSaml from "./auth/authSaml/authSamlReducers";
import home from "./home/programsReducers";
import modules from "./modules/programsReducers";
import learningObjects from "./learningObjects/learningObjectsReducers";
import stateHistory from "./stateHistory/stateHistoryReducers";
import timerHistory from './timerHistory/timerHistoryReducers'
import getUserObjectStatus from "./userObjectStatus/getUserObjectStatus/userObjectStatusReducers";
import postUserObjectStatus from "./userObjectStatus/postUserObjectStatus/userObjectStatusReducers";
import putUserObjectStatus from "./userObjectStatus/putUserObjectStatus/userObjectStatusReducers";
import progressAndScores from './progressAndScore/scoresReducer';
import postQuizResult from "./quizResult/quizResultReducers";
import postScoreResult from "./scoreResult/scoreResultReducers";
import scoresTop from "./progressAndScore/scoresTopReducer";
import news from "./news/newsReducers";

const combinedReducer = combineReducers({
  auth,
  home,
  modules,
  learningObjects,
  stateHistory,
  getUserObjectStatus,
  postUserObjectStatus,
  putUserObjectStatus,
  progressAndScores,
  postQuizResult,
  authSaml,
  scoresTop,
  timerHistory,
  news,
  postScoreResult,
});


export const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT_RESET_STORE") {
    state = undefined
  }

  if (action.type === "RESET") {
    state = undefined
  }

  if (action.type === "TIMER_HISTORY_RESET") {
    state = undefined
  }
  return combinedReducer(state, action);
}

export default rootReducer;
