const prefix = "NEWS";

export const NEWS_TYPES = {
  NEWS_START: `${prefix}_NEWS_START`,
  NEWS_SUCCESS: `${prefix}_NEWS_SUCCESS`,
  NEWS_ERROR: `${prefix}_NEWS_ERROR`,

  NEWS_ID_START: `${prefix}_ID_START`,
  NEWS_ID_SUCCESS: `${prefix}_ID_SUCCESS`,
  NEWS_ID_ERROR: `${prefix}_ID_ERROR`,

  NEWS_GET_ORDER_START: `${prefix}_NEWS_GET_ORDER_START`,
  NEWS_GET_ORDER_SUCCESS: `${prefix}_NEWS_GET_ORDER_SUCCESS`,
  NEWS_GET_ORDER_ERROR: `${prefix}_NEWS_GET_ORDER_ERROR`,
};
