import { PUTUSEROBJECTSTATUS_TYPES } from "../putUserObjectStatus/userObjectStatusActionTypes";

const initialState = {
  putUserObjectStatus: [],
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PUTUSEROBJECTSTATUS_TYPES.PUT_USER_OBJECT_STATUS_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case PUTUSEROBJECTSTATUS_TYPES.PUT_USER_OBJECT_STATUS_SUCCESS:
      return {
        ...state,
        putUserObjectStatus: payload.data,
        loading: false,
      };
    case PUTUSEROBJECTSTATUS_TYPES.PUT_USER_OBJECT_STATUS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
