const prefix = "SCORES";

export const SCORES_TYPES = {
  SCORES_BY_PROGRAM_START: `${prefix}_BY_PROGRAM_ID_START`,
  SCORES_BY_PROGRAM_SUCCESS: `${prefix}_BY_PROGRAM_ID_SUCCESS`,
  SCORES_BY_PROGRAM_ERROR: `${prefix}_BY_PROGRAM_ID_ERROR`,

  SCORES_BY_MODULE_START: `${prefix}_BY_MODULE_ID_START`,
  SCORES_BY_MODULE_SUCCESS: `${prefix}_BY_MODULE_ID_SUCCESS`,
  SCORES_BY_MODULE_ERROR: `${prefix}_BY_MODULE_ID_ERROR`,
};
