import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import AuthChecker from "shared/utils/authChecker";
import AuthToken from "modules/shared/authToken";
import { useFetchGetDetailUser } from "shared/hooks/useFetchRankings";

const PrivateRouter = ({ currentUser, component: Component, ...rest }) => {
  const [status, setStatus] = useState(true);

  const { data, loading } = useFetchGetDetailUser(localStorage.getItem("employeeID")?.trim());

  useEffect(() => {
    if (!loading && data != undefined) {

      setStatus(data?.status);
    }
  }, [data, loading]);



  return (
    <Route
      {...rest}
      render={(props) => {
        /*    const authChecker = new AuthChecker(currentUser); */

        if (!AuthToken.get() /* && !authChecker.isAuthenticated */) {
          return <Redirect to="/login" />;
        }

        if (!status && props?.location?.pathname != '/') {
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRouter;

PrivateRouter.propTypes = {
  currentUser: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};
