import React from "react";

import "./loadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="containerLoading">
      <div
        className="root"
        style={{
          width: 150,
          height: 150,
        }}
      >
        <div className="loading loading--full-height"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
