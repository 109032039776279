let inMemoryToken = null;

export default class AuthToken {
  static get() {
    return inMemoryToken || localStorage.getItem("token") || null;
  }

  static set(token, rememberMe) {
    if (rememberMe) {
      localStorage.setItem("token", token || "");
    } else {
      inMemoryToken = token;
      localStorage.setItem("token", "");
    }
  }
  
  static setRemove(removeToken){   
    if(removeToken){
      localStorage.removeItem('token');
      };
    }

}
