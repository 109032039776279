import { SCOREQUIZRESULT_TYPES } from "./scoreResultActionTypes";

const initialState = {
  postScoreResult: {},
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SCOREQUIZRESULT_TYPES.SCORE_USER_QUIZ_RESULT_STATUS_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case SCOREQUIZRESULT_TYPES.SCORE_USER_QUIZ_RESULT_STATUS_SUCCESS:
      return {
        ...state,
        postScoreResult: payload.data,
        loading: false,
      };
    case SCOREQUIZRESULT_TYPES.SCORE_USER_QUIZ_RESULT_STATUS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
