import { LEARNING_OBJECTS_TYPES } from "./learningObjectsActionTypes";
import {
	filterLearningObjectsByStatus,
	filterQuizzesByStatus,
} from "shared/utils/filterDataByStatus";

const initialState = {
	module: {
		id: null,
		title: "",
		natacion: "",
		ciclismo: "",
		carrera: "",
		competenciaImg: "",
		introMessage: "",
		finalMessage: "",
		insigniaBackground: "",
		totalKms: "",
		textTotalKmQuiz: ""
	},
	learningObjects: [],
	quizzes: [],
	loading: true,
	error: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LEARNING_OBJECTS_TYPES.LEARNING_OBJECTS_BY_MODULE_ID_START:
			return {
				...state,
				loading: true,
			};
		case LEARNING_OBJECTS_TYPES.LEARNING_OBJECTS_BY_MODULE_ID_SUCCESS:
			return {
				...state,
				module: {
					id: payload.data.module_id,
					title: payload.data.module_title,
					natacion: payload.data.quiz_cover_image_a,
					ciclismo: payload.data.quiz_cover_image_b,
					carrera: payload.data.quiz_cover_image_c,
					competenciaImg: payload.data.quiz_background,
					introMessage: payload.data.quiz_intro_message,
					finalMessage: payload.data.quiz_final_message,
					insigniaBackground: payload.data.quiz_insignia_background,
					totalKms: payload.data.text_total_km_quiz,
					textTotalKmQuiz: payload.data.text_total_km_quiz
				},
				learningObjects: filterLearningObjectsByStatus(
					payload.data.LearningObjects
				),
				quizzes: filterQuizzesByStatus(payload.data.Quizzes),
				loading: false,
			};
		case LEARNING_OBJECTS_TYPES.LEARNING_OBJECTS_BY_MODULE_ID_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};
