const prefix = "TIMER_HISTORY";

export const TIMER_HISTORY_TYPES = {
  GET_TIMER_HISTORY_START: `GET_${prefix}_BY_EMPLOYEE_ID_START`,
  GET_TIMER_HISTORY_SUCCESS: `GET_${prefix}_BY_EMPLOYEE_ID_SUCCESS`,
  GET_TIMER_HISTORY_ERROR: `GET_${prefix}_BY_EMPLOYEE_ID_ERROR`,

  POST_TIMER_HISTORY_START: `POST_${prefix}_START`,
  POST_TIMER_HISTORY_SUCCESS: `POST_${prefix}_SUCCESS`,
  POST_TIMER_HISTORY_ERROR: `POST_${prefix}_ERROR`,


  TIMER_HISTORY_RESET: `${prefix}_RESET`,
};
