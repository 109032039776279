import { SCORES_TOP_TYPES } from "./scoresTopActionTypes";

const initialState = {
  scoresTop: [],
  /* scoreTop: [], */
  loadingScore: true,
  errorScore: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SCORES_TOP_TYPES.SCORES_TOP_START:
      return {
        ...state,
        loadingScore: true,
      };
    case SCORES_TOP_TYPES.SCORES_TOP_SUCCESS:
      return {
        ...state,
        scoresTop: [...cleanTableList(state.scoresTop), payload],
        /*   scoreTop: payload, */
        errorScore: null,
        loadingScore: false,
      };
    case SCORES_TOP_TYPES.SCORES_TOP_ERROR:
      return {
        ...state,
        errorScore: payload,
        loadingScore: false,
      };
    case SCORES_TOP_TYPES.SCORES_TOP_RESET:
      return {
        scoresTop: []
      }
    default:
      return state;
  }
};
const cleanTableList = (table) => {
  let rankingUsersList = [];
  return table?.reduce((acc, item) => {

    if (!rankingUsersList.includes(item.module_id)) {
      const filteredData = table.filter(tItem => tItem.module_id === item.module_id);
      const lastItem = filteredData[filteredData.length - 1];
      rankingUsersList.push(item.module_id);
      acc.push(lastItem);
    }
    return acc;
  }, []);
}