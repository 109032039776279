import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import initializers from "./auth/initializers";

const middlewares = [thunk];

const enhancers = [applyMiddleware(...middlewares)];

let composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(rootReducer, composeEnhancers(...enhancers));

/* for (const initializer of initializers) {
  initializer(store);
}
 */