import { GETUSEROBJECTSTATUS_TYPES } from "./userObjectStatusActionTypes";

const initialState = {
  userObjectStatus: [],
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GETUSEROBJECTSTATUS_TYPES.GET_USER_OBJECT_STATUS_BY_EMPLOYEE_ID_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case GETUSEROBJECTSTATUS_TYPES.GET_USER_OBJECT_STATUS_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        userObjectStatus: payload.data,
        error: null,
        loading: false,
      };
    case GETUSEROBJECTSTATUS_TYPES.GET_USER_OBJECT_STATUS_BY_EMPLOYEE_ID_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
