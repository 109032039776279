const prefix = "STATE_HISTORY";

export const STATE_HISTORY_TYPES = {
  GET_STATE_HISTORY_START: `GET_${prefix}_BY_EMPLOYEE_ID_START`,
  GET_STATE_HISTORY_SUCCESS: `GET_${prefix}_BY_EMPLOYEE_ID_SUCCESS`,
  GET_STATE_HISTORY_ERROR: `GET_${prefix}_BY_EMPLOYEE_ID_ERROR`,

  POST_STATE_HISTORY_START: `POST_${prefix}_START`,
  POST_STATE_HISTORY_SUCCESS: `POST_${prefix}_SUCCESS`,
  POST_STATE_HISTORY_ERROR: `POST_${prefix}_ERROR`,

  POST_STATE_HISTORY_AVATAR: `POST_${prefix}_AVATAR`,
  GET_STATE_HISTORY_AVATAR: `GET_${prefix}_AVATAR`
};
