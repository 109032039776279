export const filterHomeByStatus = (array = [], payload) => {
	const payloadStateRedux = {
		employeeid: payload.data.employeeid,
	};

	const filterByStatus = array?.filter(
		(item) => item.program_status === true
	);

	const payloadArrayStateRedux = {
		Programs: filterByStatus,
	};
	const dataByStatus = Object.assign(
		payloadStateRedux,
		payloadArrayStateRedux
	);

	return dataByStatus;
};

export const filterModulesByStatus = (array = [], payload) => {
	const payloadStateRedux = {
		program_id: payload.data.program_id,
	};

	const filterByPublishDate = array?.filter((item, index) => {
		const now = Date.now();
		var data = new Date(item.module_admin_publishing_date);

		let validateDate;
		const distance = now - data;
		if (distance >= 0) {
			validateDate = true;
		} else {
			validateDate = false;
		}

		return validateDate;
	});

	const filterByStatus = filterByPublishDate?.filter(
		(item) => item.module_status === true
	);
	/* 	console.log(
		filterByPublishDate,
		"filtrar solo datos en los que la fecha es menor a la de hoy"
	); */
	const payloadArrayStateRedux = {
		Modules: filterByStatus,
	};
	const dataByStatus = Object.assign(
		payloadStateRedux,
		payloadArrayStateRedux
	);

	return dataByStatus;
};

export const filterNewsByStatus = (existingNews = [], payload) => {
	return existingNews?.filter(newsItem => {
		let itemDate = new Date(newsItem?.news_publish_start_date);
		const status = newsItem?.news_status;
		itemDate.setHours(itemDate.getHours() + 6);
		const timeDifference = Date.now() - itemDate;

		return status && timeDifference >= 0;
	});
};

export const filterLearningObjectsByStatus = (array = [], payload) => {
	const filterByStatus = array?.filter((item) => item.object_status === true);
	return filterByStatus;
};

export const filterQuizzesByStatus = (array = [], payload) => {
	const filterByStatus = array?.filter((item) => item.quiz_active === true);
	return filterByStatus;
};
