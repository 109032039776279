const privateRoutes = [
  /*  {
     path: "/",
     loader: () => import("./welcome/index"),
     exact: true,
   }, */
  {
    path: "/",
    loader: () => import("./start/index"),
    exact: true,
  },
  {
    path: "/home",
    loader: () => import("./home/index"),
    exact: true,
  },
  {
    path: "/:id/sliders",
    loader: () => import("./sliders/index"),
    exact: true,
  },
  {
    path: "/create-password",
    loader: () => import("./auth/Password/CreatePasswordScreen"),
    exact: true,
  },
  {
    path: "/enter-password",
    loader: () => import("./auth/Password/EnterPasswordScreen"),
    exact: true,
  },
  {
    path: "/modules/:idProgram",
    loader: () => import("./modules"),
    exact: true,
  },
  {
    path: "/:idProgram/:idModule/challenge",
    loader: () => import("./challenges/index"),
    exact: true,
  },
  {
    path: "/trivia/:idModule/:idObject",
    loader: () => import("./trivia"),
    exact: true,
  },
  {
    path: "/object/:idModule/:idObject",
    loader: () => import("./objects/index"),
    exact: true,
  },
  {
    path: "/instructions/exam",
    loader: () => import("./exam/ExamInstructions/index"),
    exact: true,
  },
  {
    path: "/avatarexam",
    loader: () => import("./exam/ExamInstructions/avatarShows.js"),
    exact: true,
  },
  {
    path: "/exam",
    loader: () => import("./exam/ExamStart/index"),
    exact: true,
  },
  {
    path: "/exam/:idQuiz/completed",
    loader: () => import("./exam/ExamCompleted/index"),
    exact: true,
  },
  {
    path: "/:idProgram/list-modules-scores",
    loader: () => import("./scores/listModulesScores/index"),
    exact: true,
  },
  {
    path: "/score/:idProgram/:idModule/:moduleTitle",
    loader: () => import("./scores/index"),
    exact: true,
  },
  {
    path: "/news",
    loader: () => import("./news/index"),
    exact: true,
  },
  {
    path: "/test",
    loader: () => import("./saml/index"),
    exact: true,
  },
  {
    path: "/seleccionAvatar",
    loader: () => import("./robot/index"),
    exact: true,
  },
  {
    path: "/avatar",
    loader: () => import("./robot/selected"),
    exact: true,
  },
  {
    path: "/encuesta/:idProgram",
    loader: () => import("./poll/index"),
    exact: true,
  },
  {
    path: "/score-global/:idProgram",
    loader: () => import("./scores/scoresGlobal"),
    exact: true,
  }
].filter(Boolean);

const publicRoutes = [
  /* {
    path: "/login",
    loader: () => import("./auth/Login/index"),
  },*/
  {
    path: "/login",
    loader: () => import("./auth/loginSaml/index"),
  },
  {
    path: "/register",
    loader: () => import("./auth/Register/index"),
  },
  {
    path: "/test-saml",
    loader: () => import("./saml/index"),
    exact: true,
  },

].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
};
