import { NEWS_TYPES } from "./newsTypes";
import { filterNewsByStatus } from "shared/utils/filterDataByStatus";

const initialState = {
	news: [],
	loading: true,
	error: null,
};

const news = (state = initialState, { type, payload }) => {
	switch (type) {
		case NEWS_TYPES.NEWS_START:
			return {
				...state,
				loading: true,
			};
		case NEWS_TYPES.NEWS_SUCCESS:
			return {
				...state,
				news: filterNewsByStatus(payload.data, payload),
				loading: false,
			};
		case NEWS_TYPES.NEWS_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case NEWS_TYPES.NEWS_ID_START:
			return {
				...state,
				loading: true,
			};
		case NEWS_TYPES.NEWS_ID_SUCCESS:
			return {
				...state,
				news: payload.data,
				loading: false,
			};
		case NEWS_TYPES.NEWS_ID_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case NEWS_TYPES.NEWS_GET_ORDER_START:
			return {
				...state,
				loading: true,
			};
		case NEWS_TYPES.NEWS_GET_ORDER_SUCCESS:
			return {
				...state,
				news: filterNewsByStatus(payload.data, payload),
				loading: false,
			};
		case NEWS_TYPES.NEWS_GET_ORDER_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default news;