import { POSTQUIZRESULT_TYPES } from "./quizResultActionTypes";

const initialState = {
  postQuizResult: {},
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case POSTQUIZRESULT_TYPES.POST_USER_QUIZ_RESULT_STATUS_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case POSTQUIZRESULT_TYPES.POST_USER_QUIZ_RESULT_STATUS_SUCCESS:
      return {
        ...state,
        postQuizResult: payload.data,
        loading: false,
      };
    case POSTQUIZRESULT_TYPES.POST_USER_QUIZ_RESULT_STATUS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
