import AutoAxios from "../shared/axios/authAxios";

export const getScoresByProgram = ({
    employeeid,
    program_id
}) => {
    return AutoAxios.get(`/score/progress/program/${employeeid}/${program_id}`);
};

export const getScoresByModule = ({
    employeeid,
    program_id
}) => {
    return AutoAxios.get(`/score/progress/modules/${employeeid}/${program_id}`);
};

export const getScoresTop = ({
    employeeid,
    program_id,
    module_id,
    sizeTable
}) => {
    return AutoAxios.get(`/score/progress/top/${employeeid}/${program_id}/${module_id}/${sizeTable}`);
};

export const scoreRanking = ({
    program_id,
    module_id,
    employeeid,
    limit
}) => {
    return AutoAxios.post(`/ranking`, {
        program_id,
        module_id,
        employeeid,
        limit
    });
};

export const getQuizzesTotalPoints = (
    module_id,
) => {
    return AutoAxios.get(`/quizzes/data/${module_id}`);
};



export const getDetaislUser = (
    employee,
) => {
    return AutoAxios.get(`/users/employee/${employee}`);
};


export const getBadges = (
    employeeid,
    program_id
) => {
    return AutoAxios.get(`/badges/${employeeid}/${program_id}`);
};
