import { PROGRAMS_TYPES } from "./programsActionTypes";
import { filterHomeByStatus } from "shared/utils/filterDataByStatus";

const initialState = {
  programs: [],
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROGRAMS_TYPES.PROGRAMS_BY_EMPLOYEE_ID_START:
      return {
        ...state,
        employeeid: payload,
        loading: true,
      };
    case PROGRAMS_TYPES.PROGRAMS_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        programs: filterHomeByStatus(payload.data.Programs, payload),
        loading: false,
      };
    case PROGRAMS_TYPES.PROGRAMS_BY_EMPLOYEE_ID_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
